$(function () {
    $('#receiving_transaction_id_issue_at_id').on('change', function () {
        const value = this.value;
        $.ajax({
            url: '/identification_types',
            type: 'GET',
            dataType: 'json',
            data: { destination_country_id: value },
            error: function (xhr, status, error) {
                console.error("AJAX Error: " + status + error);
            },
            success: function (response) {
                // console.log(response);
                if (!response) return;
                if (!response.identification_types) return;
                const idTypeEl = $('#receiving_transaction_identification_type_id');
                idTypeEl.empty().html("<option value=''>Please select</option>");
                let selectedValue = null;
                if (response.identification_types.length === 1) selectedValue = response.identification_types[0].id;
                response.identification_types.forEach(function (id_type) {
                    const selected = selectedValue == id_type.id ? "selected" : "";
                    idTypeEl.append(
                        '<option value="' +
                        id_type.id +
                        '" ' +
                        selected +
                        ">" +
                        id_type.name +
                        "</option>"
                    );
                });
            }
        })
    });

    $(document).ready(function () {
        const $backendChargeInput = $('.backend-charge');
        const transferAmount = parseFloat($('.transfer-amount').text()) || 0;
        const $localAmountSpan = $('.local-amount');

        $backendChargeInput.on('input', function () {
            const backendCharge = parseFloat($(this).val()) || 0;
            const localAmount = transferAmount - backendCharge;
            $localAmountSpan.text(localAmount.toFixed(2));
        });
    });
});
